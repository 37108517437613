import { ComponentExpansion } from "@/pages/_app";
import HomePage from "@/components/pc/v1/home";
import H5HomePage from "@/components/h5/v1/home";
import Layout from "@/components/public/page-architecture";
import SwitchTerminal from "@/components/public/switch-terminal";

const Home: ComponentExpansion = () => {
  return (
    <SwitchTerminal
      pc={<HomePage />}
      h5={<H5HomePage />}
      hasMultipleTerminal={true}
    />
  );
};

Home.getLayout = (page) => (
  <Layout hasH5={true} isCloseH5={false}>
    {page}
  </Layout>
);

export default Home;
